import React from "react";
import { Row, Col } from "reactstrap";

import { Link } from "gatsby";

function Email() {
	return (
		<Row className="emailsignup Coloured-Row-forced">
			<Col md="8" className="mx-auto col">
				<div className="text-center">
					<h4>
						Sign up for early bird news of my events, and other news and
						offerings related to embracing childlessness
					</h4>
					<br />
					<br />

					<div id="mc_embed_signup">
						<form
							action="https://butterflyandbeyond.us3.list-manage.com/subscribe/post?u=2e46dc49db2f027d8e1c1d6af&amp;id=1c24aa8768"
							method="post"
							id="mc-embedded-subscribe-form"
							name="mc-embedded-subscribe-form"
							class="validate"
							target="_blank"
							novalidate
						>
							<div id="mc_embed_signup_scroll">
								<div className="HideEmail">
									<input
										type="text"
										name="b_2e46dc49db2f027d8e1c1d6af_1c24aa8768"
										tabindex="-1"
										value=""
									></input>
								</div>
								<div class="form-group input-group">
									<div class="input-group-prepend">
										<span for="email" class="input-group-text">
											<i className="ni ni-circle-08"></i>
										</span>
									</div>
									<input
										aria-describedby="addon-right addon-left"
										placeholder="Your Name"
										class="form-control"
										name="FNAME"
										id="mce-FNAME"
										required=""
									></input>
								</div>
								<div class="form-group input-group">
									<div class="input-group-prepend">
										<span for="email" class="input-group-text">
											<i class="ni ni-email-83"></i>
										</span>
									</div>
									<input
										aria-describedby="addon-right addon-left"
										placeholder="Your Email"
										class="form-control"
										name="EMAIL"
										id="mce-EMAIL"
										required=""
									></input>
									<div class="input-group-append">
										<input
											type="submit"
											value="Subscribe"
											name="subscribe"
											id="mc-embedded-subscribe"
											class="btn btn-primary  "
										></input>
									</div>
								</div>
							</div>
						</form>
					</div>

					<br />
					<br />
					<sub>
						We will process your data in accordance with our{" "}
						<Link to="/privacy">Privacy Policy</Link>. You may withdraw this
						consent at any time by emailing us at{" "}
						<a href="butterflyandbeyond@outlook.com?subject=Email concent">
							butterflyandbeyond@outlook.com
						</a>{" "}
						We use Mailchimp as our marketing platform. By clicking above to
						subscribe, you acknowledge that your information will be transferred
						to Mailchimp for processing.
						<br />
						<a
							href="https://mailchimp.com/legal/"
							rel="noreferrer"
							target="_blank"
						>
							Learn more about Mailchimp's privacy practices here
						</a>
					</sub>
				</div>
			</Col>
		</Row>
	);
}

export default Email;
